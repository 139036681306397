@import '../../scss/variables';

.landing {
  height: 100vh;
}

.color-wave {
  position: absolute;
  width: 80rem;
  right: 0;
  top: -4rem;
  transform: rotate(-1deg);
  @media screen and (max-width: 1048px) {
    width: 45rem;
  }
  @media screen and (max-width: 700px) {
    width: 40rem;
    transform: rotate(-5deg);
    top: -8rem;
  }
}
.color-wave-bg {
  position: absolute;
  width: 140rem;
  right: -10rem;
  top: 22vh;
  transform: rotate(5deg);
  opacity: 10%;
  @media screen and (max-width: 1048px) {
    width: 100rem;
  }
  @media screen and (max-width: 500px) {
    transform: rotate(-4deg);
    width: 45rem;
    top: 5rem;
  }
}

.landing-text {
  position: absolute;
  display: flex;
  flex-flow: column wrap;
  left: 8vw;
  top: 30vh;
  @media screen and (max-width: 700px) {
    top: 25vh;
  }
  @media screen and (max-width: 400px) {
    left: 5vw;
  }

  &__title {
    cursor: default;
    font-size: 4.6rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 600px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 400px) {
      font-size: 1.75rem;
    }
  }

  &__name {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    margin: 0;
  }

  &__subtitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &.corner {
      margin: 1.5rem;
      margin-left: 2rem;
      @media screen and (max-width: 1048px) {
        width: 2.75rem;
      }
      @media screen and (max-width: 600px) {
        width: 1.75rem;
        margin: 1rem;
      }
      @media screen and (max-width: 400px) {
        width: 1.75rem;
        margin: 0.75rem;
        margin-top: 0;
      }
    }
    &-text {
      font-size: 1.35rem;
      font-weight: 100;
      padding-top: 2rem;
      width: 50rem;
      @media screen and (max-width: 1048px) {
        font-size: 1.25rem;
        width: 40rem;
      }
      @media screen and (max-width: 850px) {
        font-size: 1.15rem;
        width: 25rem;
      }
      @media screen and (max-width: 500px) {
        width: 20rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 1rem;
        padding-top: 0.5rem;
        margin: 0.5rem;
        width: 15rem;
      }
    }
  }
}

.bottom {
  position: absolute;
  width: 100vw;
  bottom: 1rem;
  display: flex;
  justify-content: center;
}

.scroll-indicator {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 1px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;

  &__wheel {
    width: 2px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  90% {
    opacity: 0.75;
  }
  100% {
    transform: translateY(8px);
    opacity: 0;
  }
}

@keyframes wave {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5rem, -5rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

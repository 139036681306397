@import '../../scss/variables';

.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 1rem;

  &__bar {
    background: white;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    height: 0.25rem;
  }
}

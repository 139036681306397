@import '../../scss/variables';

.about {
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5rem;
  margin-top: 0;
  height: 100%;

  &__mobile {
    flex-direction: column;
    margin-top: 4rem;
    height: 90%;
  }
}

.list {
  display: flex;
  flex-direction: column;

  &__middle {
    margin-left: 10rem;
    margin-right: 10rem;
    @media screen and (max-width: 1100px) {
      margin-left: 5rem;
      margin-right: 5rem;
    }
  }
  &__info {
    height: 70%;
    display: flex;
    flex-direction: row;

    &.left {
      animation-name: fadeFromLeft;
      animation-duration: 2.5s;
    }
    &.right {
      animation-name: fadeFromRight;
      animation-duration: 2.5s;
    }
    &.mobile {
      margin-top: 2rem;
    }
  }
}

.vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 2rem 1.2rem 2rem 1.2rem;
  width: 5rem;

  &.left {
    align-items: flex-start;
  }
  &.right {
    align-items: flex-end;
  }
  &__mobile {
    width: auto !important;

    &.left {
      margin-left: 0;
    }
    &.right {
      margin-right: 0;
    }
  }
}

.info-text {
  font-size: 1.5rem;
  margin: 0;
  @media screen and (max-width: 530px) {
    font-size: 1rem;
  }
}

.icon {
  //height: 10rem;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(1.1);
  }
}

.divider {
  border-left: 0.25rem solid white;
  height: 90%;
  border-radius: 1rem;
  transform: translateY(7%);
}

.profile {
  width: 20rem;
  pointer-events: none;
  animation-name: scale;
  animation-duration: 2s;
  &__mobile {
    width: 12rem;
  }
}
.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-evenly;
  margin: 3rem;

  animation-name: fade;
  animation-duration: 2.5s;

  &__link {
    cursor: pointer;
    transition: 0.2s ease-out;
    &:hover {
      transform: scale(1.1);
    }
    &.mobile {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

.mobile-rigth {
  height: 70%;
  display: flex;
  flex-direction: row;
  animation-name: fadeFromRight;
  animation-duration: 1.5s;
}
.mobile-left {
  height: 70%;
  display: flex;
  flex-direction: row;
  animation-name: fadeFromLeft;
  animation-duration: 1.5s;
}

.color-wave-about {
  pointer-events: none;
  position: absolute;
  width: 130rem;
  top: 20vh;
  left: -35vw;
  opacity: 10%;
  transform: rotate(48deg);
  overscroll-behavior-y: inherit;

  &:hover {
    opacity: 0;
  }

  @media screen and (max-width: 1048px) {
    width: 100rem;
  }
  @media screen and (max-width: 500px) {
    width: 80rem;
    left: -45vw;
  }
}
.row {
  display: flex;
  flex-direction: row;
}
.animation {
  &__left {
    animation-name: fadeFromLeft;
    animation-duration: 1.5s;
  }
  &__right {
    animation-name: fadeFromRight;
    animation-duration: 1.5s;
  }
}

@keyframes fadeFromLeft {
  0% {
    transform: translateX(-30rem);
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}
@keyframes fadeFromRight {
  0% {
    transform: translateX(30rem);
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}

@keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

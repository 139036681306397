@import './scss/variables';
@import '@fontsource/fira-mono';

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Fira Mono', sans-serif;
  font-size: 16px;
  color: $text-color;
  background-color: $primary-color;
  overflow-x: hidden;
  //max-height: 200vh;
}

.App {
  display: flex;
  flex-direction: column;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Fira Mono', sans-serif;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.41px;
}

::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-track {
  background: #0f1014;
  border-left: 2px solid #0f1014;
}
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 1rem;
  transition: 1s;
}
